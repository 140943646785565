export const environment = {
	production: false,
	// apiUrl: 'http://localhost:3000',
	apiUrl: 'https://admin-sdk-connect365-nodejs.onrender.com',
	socketUrl: 'wss://wzrkjuvzbh.execute-api.us-east-1.amazonaws.com/production',
	clientId: '16867oefojr499vaupbtrbh9lp',
	redirectUri: 'http://localhost:4200/auth/login',
	logoutUri: 'https://login.microsoftonline.com/common/oauth2/logout',
	issuer: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_FMnbSzNNa',
	poolId: 'us-east-1_FMnbSzNNa',
	version: 'version LOCAL',
	requireHttps: true,
	sonarqubeToken: 'sqp_0629868c4cd09e57aac5351888e560813f70d3f9',
	firebaseConfig: {
		apiKey: 'AIzaSyDnzXL-HMer7MCKu9Ph5FYwaQDOvcleY2Y',
		authDomain: 'development-155906.firebaseapp.com',
		// apiKey: 'AIzaSyCqmn7NAFg174f8nX4_Xp128qGWpQF3rg4', // CUENTA PERSONAL
		// authDomain: 'arched-autumn-276622.firebaseapp.com', // CUENTA PERSONAL
		SAMLAuthProvider: 'saml.azuread',
	},
}
